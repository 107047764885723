import React from 'react'
import { Link } from 'gatsby'
import { srcSetProps, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ buildingBlocks }) => {
  if (!buildingBlocks || buildingBlocks.isHide) return null

  return (
    <div className={styles.buildingBlocks}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{buildingBlocks.title}</h2>
        <p className={styles.description}>{buildingBlocks.description}</p>

        <div className={styles.container}>
          <img
            {...srcSetProps(sanityImageUrl(buildingBlocks.image))}
            alt={buildingBlocks.image?.caption}
            className={styles.image}
          />
          <img
            {...srcSetProps(sanityImageUrl(buildingBlocks.imageMobile))}
            alt={buildingBlocks.imageMobile?.caption}
            className={styles.imageMobile}
          />

          <div className={styles.text}>
            <p className={styles.textTop}>{buildingBlocks.buildingBlocksTop}</p>
            <p className={styles.textProtocol}>{buildingBlocks.buildingBlocksTitle}</p>
            <p className={styles.textDescription}>{buildingBlocks.buildingBlocksDescription}</p>

            {buildingBlocks.buttonText && buildingBlocks.buttonUrl && (
              <Link to={buildingBlocks.buttonUrl} className={styles.link}>
                {buildingBlocks.buttonText}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
