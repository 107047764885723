import React from 'react'
import Layout from '../utils/layout'
import SEO from '../utils/seo'
import { sortComponents } from '../utils/format'
import ProductSchema from '../components/Shared/Schema/Product'
import FaqSchema from '../components/Shared/Schema/Faq'
import Info from '../components/PdpHairPage/Info'
import Ingredients from '../components/PdpHairPage/Ingredients'
import Table from '../components/PdpHairPage/Table'
import Derma from '../components/PdpHairPage/Derma'
import BuildingBlocks from '../components/PdpHairPage/BuildingBlocks'
import HowItWorks from '../components/PdpHairPage/HowItWorks'
import Price from '../components/PdpHairPage/Price'
import Customers from '../components/PdpHairPage/Customers'
import Explain from '../components/PdpHairPage/Explain'
import Protocols from '../components/PdpHairPage/Protocols'
import Reviews from '../components/PdpHairPage/Reviews'
import References from '../components/PdpHairPage/References'
import Comment from '../components/PdpHairPage/Comment'
import Faq from '../components/PdpHairPage/Faq'
import FoodAndDrugAdministration from '../components/PdpHairPage/FoodAndDrugAdministration'
import Wim from '../components/PdpHairPage/Wim'

export default ({ pageContext: { data } }) => {
  const components = [
    {
      render: <Ingredients ingredients={data.ingredients} />,
      priority: data.ingredients?.priority || 99,
    },
    {
      render: <Table table={data.table} />,
      priority: data.table?.priority || 99,
    },
    {
      render: <Derma derma={data.derma} />,
      priority: data.derma?.priority || 99,
    },
    {
      render: <BuildingBlocks buildingBlocks={data.buildingBlocks} />,
      priority: data.buildingBlocks?.priority || 99,
    },
    {
      render: <HowItWorks howItWorks={data.howItWorks} />,
      priority: data.howItWorks?.priority || 99,
    },
    {
      render: <Price price={data.price} />,
      priority: data.price?.priority || 99,
    },
    {
      render: <Customers customers={data.customers} />,
      priority: data.customers?.priority || 99,
    },
    {
      render: <Explain explain={data.explain} />,
      priority: data.explain?.priority || 99,
    },
    {
      render: <Protocols protocols={data.protocols} />,
      priority: data.protocols?.priority || 99,
    },
    {
      render: <Reviews reviews={data.reviews} />,
      priority: data.reviews?.priority || 99,
    },
    {
      render: <References references={data.references} />,
      priority: data.references?.priority || 99,
    },
    {
      render: <Comment comment={data.comment} />,
      priority: data.comment?.priority || 99,
    },
    {
      render: <Faq faq={data.faq} />,
      priority: data.faq?.priority || 99,
    },
    {
      render: <FoodAndDrugAdministration foodAndDrugAdministration={data.foodAndDrugAdministration} />,
      priority: data.foodAndDrugAdministration?.priority || 99,
    },
    {
      render: <Wim wim={data.wim} />,
      priority: data.wim?.priority || 99,
    },
  ].sort(sortComponents)

  return (
    <Layout data={data} signUpUrl={data.info?.buttonUrl} signUpText={data.info?.buttonText}>
      <SEO {...data.seo} />
      <ProductSchema {...data.productSchema} />
      {(data?.faq?.faqList?.items || data?.faq?.items) && (
        <FaqSchema questions={data?.faq?.faqList?.items || data?.faq?.items} />
      )}
      <Info info={data.info} />
      {components.map(component => component.render)}
    </Layout>
  )
}
