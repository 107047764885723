import React from 'react'
import { srcSetProps, sanityImageUrl, urlWithSearchParamsHandler, prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ price }) => {
  if (!price || price.isHide) return null

  return (
    <div className={styles.price}>
      <div className={styles.wrap}>
        <div className={styles.pricing}>
          <h2 className={styles.title}>{price.title}</h2>
          <p className={styles.description}>{price.description}</p>
          <div className={styles.table}>
            <p className={styles.tableTitle}>{price.optionsTitle}</p>
            <table className={styles.tableInside}>
              <thead>
                <tr>
                  <td></td>
                  <td>{price.options_title_column_1}</td>
                  <td>{price.options_title_column_2}</td>
                </tr>
              </thead>
              <tbody>
                {price.optionsList &&
                  price.optionsList.map(row => (
                    <tr key={row._key}>
                      <td>{row.title}</td>
                      <td>{row.column_1}</td>
                      <td>{row.column_2}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className={styles.table}>
            <p className={styles.tableTitle}>{price.devicesTitle}</p>
            <table className={styles.tableInside}>
              <thead className={styles.row}>
                <tr>
                  <td></td>
                  <td>{price.devices_title_column_1}</td>
                  <td>{price.devices_title_column_1}</td>
                </tr>
              </thead>
              <tbody>
                {price.devicesList &&
                  price.devicesList.map(row => (
                    <tr key={row._key}>
                      <td>{row.title}</td>
                      <td>{row.column_1}</td>
                      <td>{row.column_2}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <p className={styles.text}>{prepareParagraph(price.text)}</p>
          <a href={price.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.button}>
            {price.buttonText}
          </a>
        </div>
        <div className={styles.border} />
        <div className={styles.image}>
          <h2 className={styles.title}>{price.rightTitle}</h2>
          <img
            {...srcSetProps(sanityImageUrl(price.rightImage))}
            alt={price.rightImage?.caption}
            className={styles.imageSrc}
          />
        </div>
      </div>
    </div>
  )
}
