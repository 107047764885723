import React from 'react'
import { sanityImageUrl } from '../../../utils/format'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ protocols }) => {
  if (!protocols || protocols.isHide) return null

  return (
    <div className={styles.protocols}>
      <div className={styles.wrap}>
        <h2 className={styles.title}>{protocols.title}</h2>
        <p className={styles.description} dangerouslySetInnerHTML={{ __html: protocols.description }} />

        <div className={styles.items}>
          {protocols.items &&
            protocols.items.map(protocol => (
              <div className={styles.item} key={protocol._key}>
                <div className={styles.image}>
                  <img src={sanityImageUrl(protocol.image)} className={styles.imageSrc} alt={protocol.image?.caption} />
                  <span className={styles.price}>{protocol.price}</span>
                </div>
                <p className={styles.name}>{protocol.title}</p>
                <p className={styles.info}>{protocol.Description}</p>
                {protocol.buttonText && protocol.buttonUrl && (
                  <Link to={protocol.buttonUrl} className={styles.link}>
                    {protocol.buttonText}
                  </Link>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}
