import React from 'react'
import { srcSetProps, sanityImageUrl, prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ derma }) => {
  if (!derma || derma.isHide) return null

  return (
    <div className={styles.derma}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{derma.title}</h2>
          <p className={styles.description}>{derma.description}</p>
        </div>

        <div className={styles.middle}>
          <div className={styles.item}>
            <p className={styles.name}>{derma.rollerName}</p>
            <img
              {...srcSetProps(sanityImageUrl(derma.rollerImage))}
              alt={derma.rollerImage?.caption}
              className={styles.image}
            />
            <p className={styles.text}>{derma.rollerTitle}</p>
            <p className={styles.info}>{prepareParagraph(derma.rollerDescription)}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.vs}>{derma.vs}</p>
          </div>
          <div className={styles.item}>
            <p className={styles.name}>{derma.stampName}</p>
            <img
              {...srcSetProps(sanityImageUrl(derma.stampImage))}
              alt={derma.stampImage?.caption}
              className={styles.image}
            />
            <p className={styles.text}>{derma.stampTitle}</p>
            <p className={styles.info}>{prepareParagraph(derma.stamptDescription)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
