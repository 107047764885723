import React from 'react'
import { Link } from 'gatsby'
import styles from './style.module.sass'

export default ({ explain }) => {
  if (!explain || explain.isHide) return null

  return (
    <div className={styles.explain}>
      <div className={styles.wrap}>
        <div className={styles.top}>
          <h2 className={styles.title}>{explain.title}</h2>
          {explain.buttonText && explain.buttonUrl && (
            <Link className={styles.button} to={explain.buttonUrl}>
              {explain.buttonText}
            </Link>
          )}
        </div>

        <div className={styles.video}>
          <iframe
            src={`https://www.youtube.com/embed/${explain.youtubeVideoId}`}
            frameBorder="0"
            allowfullscreen=""
          ></iframe>
        </div>
      </div>
    </div>
  )
}
